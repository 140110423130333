import React,{Suspense} from "react";
import Header from './Header.js';



function App(){

    return(
        <div className="App">
            <Header/>

        </div>
    )
}
export default App;